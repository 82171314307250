.full-screen{
    background-color: #00aeac;
}
.page{
    height:100%;
 background-color: #00aeac;
}
.login-card{
    margin-top: 35%;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 20px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
}

.line-1{
    font-size: small;
    text-align: center;
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.line-2,.line-3{
    font-size: medium;
    font-family: sans-serif;
}
.last-line{
    text-align: center;
    margin-top: 30px;
}
.button-login {
    width: 100%;
    background-color:#06b4d6 ;
    border-radius: 10px;
    border: none;
  }
  .button-login:hover{
    background-color: #05cff7;
    height: 14;
  }
.authenticate{
    width: 10%;
    height: 10%;
  margin-left: 42%;
  margin-top: 5px;

}


/* Media query for mobile phones */
@media only screen and (max-width: 768px) {
    .login-card {
        margin-top: 5%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;
        border-radius: 8px;
    }

    .line-1 {
        font-size: x-small;
        margin-bottom: 8px;
    }

    .line-2, .line-3 {
        font-size: small;
    }

    .last-line {
        margin-top: 10px;
    }

    .button-login {
        width: 90%;
        margin-left: 5%;
    }

    .authenticate {
        width: 30%;
        height: auto;
        margin-left: 35%;
    }
}

.password-input-container {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    /* color: #5A8D8E; */
  }
  
