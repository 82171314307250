 /* Newpassword.css */
 .new-password-container .card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  .new-password-container .card-header {
    background-color:#DAF1F6;
    border-radius: 10px 10px 0 0;
  }
  .new-password-container {
    background-color: #00aeac;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reset {
    width: 100%;
    background-color:#06b4d6 ;
    border-radius: 10px;
    border: none;
  }
  .reset:hover{
    background-color: #05cff7;
    height: 14;
  }
 
  .password-input-container {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    /* color: #5A8D8E; */
  }
  